<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-on="{ ...on, ...attrs }">mdi-dots-vertical</v-icon>
      </template>

      <v-list>
        <v-list-item @click="clickedMenuItem('new-delivery')">
          <v-list-item-icon>
            <v-icon> mdi-truck-delivery </v-icon>
          </v-list-item-icon>
          <v-list-item-title>New Delivery</v-list-item-title>
        </v-list-item>
        <v-list-item @click="clickedMenuItem('new-production')">
          <v-list-item-icon>
            <v-icon> mdi-account-hard-hat </v-icon>
          </v-list-item-icon>
          <v-list-item-title>New Production</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { handleMenuItem } from "./eventHandler.js";
import moment from "moment";

export default {
  props: {
    dateRange: {
      type: Object,
      required: false,
    },
    rowItem: {
      type: Object,
      required: false,
    },
  },
  data: () => ({
    product: null
  }),
  created(){
    this.product = this.rowItem.row.product;
  },
  computed: {
    startDate() {
      return this.dateRange
        ? moment(this.dateRange.start).format("YYYY-MM-DD")
        : null;
    },
    endDate() {
      return this.dateRange
        ? moment(this.dateRange.end).format("YYYY-MM-DD")
        : null;
    },
  },
  methods: {
    clickedMenuItem(menu) {
      const event = handleMenuItem(
        menu,
        this.product
      );
      this.$emit("open-dialog", event);
    },
    openNewPage(routeObj) {
      const { name, params } = routeObj;
      const route = this.$router.resolve({ name, params });
      const url = route.href + "?" + this.serializeParams(params);
      window.open(url, "_blank");
    },
    serializeParams(params) {
      const encodedParams = Object.keys(params)
        .map(
          (key) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
        )
        .join("&");
      return encodedParams;
    },
  },
};
</script>
