// Event handler for Flow Control

import moment from 'moment';


// function handleDefaultItem(item) {
//     const selectedDate = moment(item.time.start).format("YYYY-MM-DD");

//     const dialogMode = "debug";
//     const dialogTitle = `${item.teacherName}'s ${item.type} - ${selectedDate}`;
//     const dialogData = JSON.stringify(item, null, 2); // Beautify the JSON output

//     return { dialogTitle, dialogData, dialogMode };
// }

function handleDefaultMenuItem() {
    const selectedDate = moment().format("YYYY-MM-DD");

    const dialogMode = "debug";
    const dialogTitle = `${selectedDate}`;
    const dialogData = {}; // Beautify the JSON output

    return { dialogTitle, dialogData, dialogMode };
}

function handleDelivery(delivery, product, selectedDate = null) {

    const dialogMode = "DeliverySchedule";
    const dialogTitle = `${delivery ? 'New' : 'Edit'} Delivery`;

    const date = selectedDate ? selectedDate : moment().format('YYYY-MM-DD');
    const emptyDelivery = {
        start: date,
        end: moment(date).add(1, 'days').format('YYYY-MM-DD')
    }

    const dialogData = {
        value: delivery ? delivery : emptyDelivery,
        product
    };

    return { dialogTitle, dialogData, dialogMode };
}

function handleProduction(production, product, selectedDate) {

    const dialogMode = "ProductionSchedule";
    const dialogTitle = `${production ? 'New' : 'Edit'} Production`;

    const emptyProduction = {
        start: selectedDate,
        end: moment(selectedDate).add(1, 'days').format('YYYY-MM-DD')
    }

    const dialogData = {
        value: production ? production : emptyProduction,
        product
    };

    return { dialogTitle, dialogData, dialogMode };
}

export function handleMenuItem(menu, product) {

    const selectedDate = moment();

    switch (menu) {
        case "new-delivery":
            return handleDelivery(null, product, selectedDate);
        case "new-production":
            return handleProduction(null, product, selectedDate);
        default:
            return handleDefaultMenuItem();
    }
}

/* eslint-disable no-unused-vars */
export function handleItem(event, dataGroups) {

    const item = event.item
    const group = dataGroups.find(g => g.id == item.productId)
    const product = group.product;

    if (item.type == "delivery") {
        const delivery = group.deliveries.find(d => d.id == item.deliveryId);
        return handleDelivery(delivery, product);
    } else if (item.type == "production") {
        const production = group.productions.find(p => p.id == item.productionId);
        return handleProduction(production, product);
    }

}

export function handleCell(cell, dataGroups) {
    const row = cell.row.row;
    const slot = cell.slot.moment;

    const productId = row.productId;
    const group = dataGroups.find(g => g.id == productId)
    const product = group.product;

    const startDate = slot.format("YYYY-MM-DD");

    switch (row.type) {
        case "delivery":
            return handleDelivery(null, product, startDate);
        case "production":
            return handleProduction(null, product, startDate);
    }
}